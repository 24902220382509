<script setup>
import unknownMoney from '@/../img/calculator/unknown-money.svg';
import Button from '@/Components/Button/Button.vue';
import Checkmark from '@/Components/Input/Checkmark.vue';
import { mapErrors } from '@/utilities';
import { useGtm } from '@gtm-support/vue-gtm';
import { useForm } from '@inertiajs/vue3';
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import BonusPage from './Partials/BonusPage.vue';
import MoneyBar from './Partials/MoneyBar.vue';
import Subtitle from './Partials/Subtitle.vue';
import Title from './Partials/Title.vue';

const { t } = useI18n();

const route = inject('route');
const displaying = ref('Start');

const emits = defineEmits(['submissionSuccessful']);

const errors = ref([]);

const gtm = useGtm();

const form = useForm({
    nickname: '',
    email: '',
    // postcode: '',
    // phone_number: '',
    age: '',
    tag: 'calculator',
    affiliate_code: '',
    terms_and_conditions: false,
});

const goNext = () => {
    errors.value = [];

    if (isNaN(parseInt(form.age))) {
        errors.value.push(t('Enter your age as a integer'));
        return;
    }
    if (parseInt(form.age) < 15) {
        errors.value.push(t('You are too young to work, we are looking forward to welcome you on your 15th birthday'));
        return;
    }

    sendForm();
};

const sendForm = () => {
    form.transform((data) => ({
        ...data,
        affiliate_code: getAffiliateCode(),
        tag: 'calculator',
    })).post(route('form.signup'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: (page) => {
            fbq('track', 'form-submission', { content_category: 'calculator' });
            gtm.trackEvent({
                event: 'employee_lead',
                landingspage: 'calculator',
                age: parseInt(form.age),
            });
            emits('submissionSuccessful');

            displaying.value = 'Thanks';
        },
    });
};

const wage = computed(() => {
    let wages = {
        15: '4,88',
        16: '5.63',
        17: 6.43,
        18: 7.06,
        19: 8.25,
        20: 11.04,
        21: 13.79,
        22: 15.13,
        23: 16.43,
        24: 16.66,
        25: 16.89,
        26: 17.34,
    };

    if (parseInt(form.age) > 26) {
        return 14.29;
    }
    return wages[parseInt(form.age)];
});

const getAffiliateCode = () => {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
        return urlParams.get('code');
    }
    return '';
};
</script>

<template>
    <BonusPage v-model:displaying="displaying" tag="calculator" :age="form.age" :wage="wage">
        <div v-show="displaying == 'Start'">
            <Title :text="$t('Curious <strong>how much</strong> you can <strong>earn</ strong> with us?')" />

            <Subtitle>
                {{ $t('Enter your details and we will make a calculation!') }}
            </Subtitle>

            <img :src="unknownMoney" class="w-full mb-8 max-w-130" :alt="$t('How much?')" />

            <FormKit type="form" id="signup-bonus" @submit="goNext" :actions="false">
                <div class="flex flex-col gap-4 max-w-130">
                    <FormKit
                        type="text"
                        name="age"
                        :placeholder="$t('Age')"
                        v-model="form.age"
                        :value="form.age"
                        :errors="errors"
                        validate="required"
                    />

                    <FormKit
                        type="text"
                        name="name"
                        :placeholder="$t('Name')"
                        v-model="form.nickname"
                        :errors="form.errors.nickname"
                        :value="form.nickname"
                        validate="required"
                    />
                    <FormKit
                        type="text"
                        name="email"
                        :placeholder="$t('Email')"
                        v-model="form.email"
                        :errors="form.errors.email"
                        :value="form.email"
                        validate="required"
                    />

                    <Checkmark v-model:value="form.terms_and_conditions" :error="form.errors.terms_and_conditions">
                        <div class="leading-tight">
                            {{ $t('I accept the') }}
                            <a target="_blank" class="font-bold underline" :href="route('terms')">
                                {{ $t('general terms') }}</a
                            >
                            {{ $t('and') }}
                            <a target="_blank" class="font-bold underline" :href="route('privacy')">
                                {{ $t('privacy statement') }}</a
                            >
                        </div>
                    </Checkmark>

                    <Button :text="$t('Calculate')" />
                </div>
            </FormKit>
        </div>
        <div v-show="displaying == 'SignupForm'">
            <Title
                :text="
                    $t(
                        `<strong>Sign up now</strong> for a chance to win  <span class=\&quot;inline-block font-bold\&quot;>€ 1000</span> in Bitcoins!`
                    )
                "
            />

            <Subtitle>
                {{
                    $t(
                        'Fill in your details below and who knows you might be the lucky winner from all the registrations! In addition, stay informed about the latest developments of Flex@ble!'
                    )
                }}
            </Subtitle>

            <MoneyBar :wage="wage" />

            <div class="my-4">{{ $t('Average hourly wage at Flex@ble based on the entered age.') }}</div>
        </div>
    </BonusPage>
</template>
